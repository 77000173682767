"use client";
import { GridTileImageSkeleton } from "@/components/grid/tile-skeleton";

export function CarouselSkeleton() {
  return (
    <>
      {[1, 2, 3, 4].map((i) => (
        <div key={i} className="mt-4">
          <h2 className="animate-pulse bg-neutral-500 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-800 rounded-full w-[220px] mb-4 lg:mt-8 text-xl md:text-2xl font-bold tracking-tight md:font-extrabold lg:leading-none ">
            {"    . "}
          </h2>
          <div className=" w-full overflow-x-auto pb-6 pt-1">
            <ul className="flex animate-carousel gap-4">
              {[1, 2, 3, 4, 5, 6].map((ix) => (
                <li
                  key={ix}
                  className="relative aspect-square h-[30vh] max-h-[275px] w-2/3 max-w-[475px] flex-none md:w-1/3"
                >
                  <GridTileImageSkeleton />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
}
