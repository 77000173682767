import clsx from "clsx";

export function GridTileImageSkeleton() {
  return (
    <div
      className={clsx(
        "flex h-full w-full items-center justify-center overflow-hidden rounded-lg border  animate-pulse bg-neutral-500 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-800 "
      )}
    />
  );
}
